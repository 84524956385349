import CreateContracts from "pages/Contracts/CreateInvoice/CreateInvoice";
import { getProfileStatus } from "../slices/thunk";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { jwtDecode } from "jwt-decode";

interface MenuItem {
  label: string;
  isHeader?: boolean;
  id?: string;
  icon?: string;
  link?: string;
  click?: (e: React.MouseEvent) => void;
  stateVariables?: boolean;
  subItems?: MenuItem[];
}

interface Roles {
  [key: string]: string[];
}

interface NavdataProps {
  userRole: string;
}
interface JwtPayload {
  userType: string;
}

const Navdata: React.FC<NavdataProps> = ({ userRole }) => {
  const profileStatus = useSelector(
    (state: any) => state.UserProfile.userProfileStatus
  );
  const dispatch: any = useDispatch();
  useEffect(() => {
    const fetchProfileStatus = async () => {
      await dispatch(getProfileStatus());
    };

    fetchProfileStatus();
  }, [dispatch]);

  //

  const token = localStorage.getItem("authUser");
  let decoded: any = "";
  if (token !== null) {
    decoded = jwtDecode<JwtPayload>(token);
  } else {
    console.error("Token is null");
  }
  const cleanedString = decoded.roles.replace(/'/g, '"');
  // Define roles and their corresponding permissions
  const roles: Roles = {
    admin: [
      "dashboard",
      "adminContractors",
      "adminHomeowners",
      "adminRealestateAgents",
      "adminAdminsData",
      "credentials",
      "adminForms",
      "adminSubscribers",
      "createSwornHomeOwner",
      "createSwornContracts",
      "createContracts",
    ],

    //admin: ["dashboard", "adminContractors", "invoice","listview", 'overview','contacts','contractors', 'createinvoice',  "projects",'apps-chat', 'legel', 'crm',"customerService","finance","sales", "credentials" ],
    homeowner: JSON.parse(cleanedString), //["dashboard", "invoice","listview", "projects", 'apps-chat', 'createContracts', 'createSwornContracts', 'createSwornHomeOwner'],
    //contractor: ["dashboard", "invoice", "listview", "leads", 'apps-chat', 'createContracts', 'createSwornContracts', 'createSwornHomeOwner', 'profile', 'profilesettings'],
    real_estate_agent: JSON.parse(cleanedString),
    broker: JSON.parse(cleanedString),
    contractor: JSON.parse(cleanedString),
    testContractor: JSON.parse(cleanedString), //["dashboard", 'profilesettings', "leads", 'createContracts', 'createSwornContracts', 'createSwornHomeOwner'],
    sales: JSON.parse(cleanedString),
    subcontractor: JSON.parse(cleanedString), //["dashboard", 'profilesettings', "leads", 'createContracts', 'createSwornContracts', 'createSwornHomeOwner'],
    legel: [
      "dashboard",
      "contracts",
      "listviewContract",
      "overviewContract",
      "createContract",
    ],
    guest: ["dashboard"],
  };

  // State variables
  const [permissions, setPermissions] = useState<string[]>([]);

  // Update state variables based on user's role
  useEffect(() => {
    setPermissions(roles[userRole] || []);
  }, [userRole]);

  // Function to check if a menu item is allowed for the current user
  const isAllowed = (permission: string) => permissions.includes(permission);

  // Your existing state variables
  const [isDashboard, setIsDashboard] = useState(false);
  const [isEcommerce, setIsEcommerce] = useState(false);
  const [isLearning, setIsLearning] = useState(false);
  const [isCourses, setIsCourses] = useState(false);
  const [isStudents, setIsStudents] = useState(false);
  const [isInstructors, setIsInstructors] = useState(false);
  const [isSupportTicket, setIsSupportTicket] = useState(false);
  const [isRealEstate, setIsRealEstate] = useState(false);
  const [isAuthentication, setIsAuthentication] = useState(false);
  const [isPages, setIsPages] = useState(false);
  const [isagent, setIsAgent] = useState(false);
  const [isagencies, setIsAgencies] = useState(false);

  // Components
  const [isBootstrapUi, setIsBootstrapUi] = useState(false);
  const [isAdvanceUi, setIsAdvanceUi] = useState(false);
  const [isCustomUi, setIsCustomUi] = useState(false);
  const [isWidgets, setIsWidgets] = useState(false);
  const [isForms, setIsForms] = useState(false);
  const [isTables, setIsTables] = useState(false);
  const [isCharts, setIsCharts] = useState(false);
  const [isIcons, setIsIcons] = useState(false);
  const [isMaps, setIsMaps] = useState(false);

  const [isOrder, setIsOrder] = useState(false);
  const [isInvoice, setIsInvoice] = useState(false);
  const [isContracts, setIsContracts] = useState(false);
  const [isShipping, setIsShipping] = useState(false);
  const [isLocalization, setIsLocalization] = useState(false);
  const [isMultiLevel, setIsMultiLevel] = useState(false);

  // Authentication
  const [isError, setIsError] = useState(false);

  // Multi Level
  const [isLevel1, setIsLevel1] = useState(false);
  const [isLevel2, setIsLevel2] = useState(false);

  // Components
  const [isAuth, setIsAuth] = useState(false);
  // ... (other state variables)

  const [iscurrentState, setIscurrentState] = useState("Dashboard");

  function updateIconSidebar(e: React.MouseEvent) {
    if (e.target && (e.target as HTMLElement).getAttribute("sub-items")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul!.querySelectorAll(".nav-icon.active");
      let activeIconItems = Array.from(iconItems);
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = (item as HTMLElement).getAttribute("sub-items");
        var menusId = document.getElementById(id!);
        if (menusId) {
          (menusId.parentElement as HTMLElement).classList.remove("show");
        }
      });
      (e.target as HTMLElement).classList.add("active");
    }
  }

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");

    if (iscurrentState !== "Dashboard") {
      setIsDashboard(false);
    }
    if (iscurrentState !== "Ecommerce") {
      setIsEcommerce(false);
    }
    if (iscurrentState !== "Learning") {
      setIsLearning(false);
    }
    if (iscurrentState !== "Invoice") {
      setIsInvoice(false);
    }
    if (iscurrentState !== "Contracts") {
      setIsContracts(false);
    }
    if (iscurrentState !== "Support Ticket") {
      setIsSupportTicket(false);
    }
    if (iscurrentState !== "Real Estate") {
      setIsRealEstate(false);
    }
    if (iscurrentState !== "Authentication") {
      setIsAuthentication(false);
    }
    if (iscurrentState !== "Pages") {
      setIsPages(false);
    }
    if (iscurrentState !== "Bootstrap UI") {
      setIsBootstrapUi(false);
    }
    if (iscurrentState !== "AdvanceUi") {
      setIsAdvanceUi(false);
    }
    if (iscurrentState !== "Custom UI") {
      setIsCustomUi(false);
    }
    if (iscurrentState !== "Widgets") {
      setIsWidgets(false);
    }
    if (iscurrentState !== "Forms") {
      setIsForms(false);
    }
    if (iscurrentState !== "Tables") {
      setIsTables(false);
    }
    if (iscurrentState !== "Charts") {
      setIsCharts(false);
    }
    if (iscurrentState !== "Icons") {
      setIsIcons(false);
    }
    if (iscurrentState !== "Maps") {
      setIsMaps(false);
    }

    if (iscurrentState !== "Orders") {
      setIsOrder(false);
    }
    // if (iscurrentState !== 'Sellers') {
    //     setIsSellers(false);
    // }
    if (iscurrentState !== "Shipping") {
      setIsShipping(false);
    }
    if (iscurrentState !== "Localization") {
      setIsLocalization(false);
    }
    if (iscurrentState !== "Auth") {
      setIsAuth(false);
    }

    if (iscurrentState !== "Auth") {
      setIsAuth(false);
    }
  }, [
    iscurrentState,
    isDashboard,
    isLearning,
    isRealEstate,
    isEcommerce,
    isSupportTicket,
    isOrder,
    isInvoice,
    isContracts,
    isShipping,
    isLocalization,
    isAuth,
    isMultiLevel,
    isAuthentication,
    isPages,
    isBootstrapUi,
    isAdvanceUi,
    isWidgets,
    isForms,
    isTables,
    isCharts,
    isIcons,
    isMaps,
  ]);

  // Your existing menuItems array
  const menuItems: any = [
    {
      label: "Menu",
      isHeader: true,
    },
    {
      id: "dashboard",
      label: "Dashboards",
      icon: "ph-gauge",
      link: "/#",
      click: function (e: any) {
        e.preventDefault();
        setIsDashboard(!isDashboard);
        setIscurrentState("Dashboard");
        updateIconSidebar(e);
      },
      stateVariables: isDashboard,
    },
    {
      id: "projects",
      label: "Projects",
      icon: "ph-gauge",
      link: "/pages-projects",
    },
    //admin
    {
      id: "adminContractors",
      label: "Contractors",
      icon: "ph-gauge",
      link: "/pages-all-contractors",
    },
    {
      id: "adminHomeowners",
      label: "All Homeowners",
      icon: "ph-gauge",
      link: "/pages-all-homeowners",
    },
    {
      id: "adminRealestateAgents",
      label: "Real Estate Agents",
      icon: "ph-gauge",
      link: "/pages-all-real-estate-agents",
    },
    {
      id: "adminAdminsData",
      label: "Admins",
      icon: "ph-gauge",
      link: "/pages-all-admins",
    },

    //admin
    {
      id: "adminForms",
      label: "TTC Forms Data",
      icon: "ph-gauge",
      link: "/pages-all-forms",
    },

    //admin
    {
      id: "adminSubscribers",
      label: "Subscribers",
      icon: "ph-gauge",
      link: "/pages-all-subscribers",
    },

    {
      id: "leads",
      label: "Leads Pro",
      icon: "ph-gauge",
      link: "/pages-projects",
    },
    {
      id: "reqLeads",
      label: "Requested Leads",
      icon: "ph-gauge",
      link: "/project-requested",
    },
    {
      id: "ongoingLeads",
      label: "Ongoing Leads",
      icon: "ph-gauge",
      link: "/project-ongoing-leads",
    },

    {
      id: "createContracts",
      label: "New Contracts",
      icon: "ph-gauge",
      link: "/get-contracts",
    },
    {
      id: "transactions",
      label: "Invoices",
      icon: "ph-file-text",
      link: "/pages-all-transactions",
    },

    {
      id: "createSwornContracts",
      label: "Contracts Sworn Contracts",
      icon: "ph-gauge",
      link: "/create-sworn-contracts",
    },

    {
      id: "createSwornHomeOwner",
      label: "Contracts",
      icon: "ph-gauge",
      link: "/create-sworn-homeowner",
    },
    {
      id: "subcontractor",
      label: "Make Your Team",
      icon: "ph-gauge",
      link: "/create-your-team",
    },

    {
      id: "apps-calendar",
      label: "Calendar",
      icon: "ph-calendar",
      link: "/apps-calendar",
    },
    {
      id: "apps-chat",
      label: "Chat",
      icon: "ph-chats",
      link: "/apps-chat",
    },
    {
      id: "invoice",
      label: "Invoice",
      icon: "ph-file-text",
      link: "/#",
      click: function (e: any) {
        e.preventDefault();
        setIsInvoice(!isInvoice);
        setIscurrentState("Invoice");
        updateIconSidebar(e);
      },
      stateVariables: isInvoice,
      subItems: [
        {
          id: "listview",
          label: "List View",
          link: "/invoices-list",
          parentId: "invoice",
        },
        {
          id: "overview",
          label: "Overview",
          link: "/apps-invoices-overview",
          parentId: "invoice",
        },
        {
          id: "createinvoice",
          label: "Create Invoice",
          link: "/apps-invoices-create",
          parentId: "invoice",
        },
      ],
    },
    {
      id: "contracts",
      label: "Contracts",
      icon: "ph-file-text",
      link: "/#",
      click: function (e: any) {
        e.preventDefault();
        setIsContracts(!isContracts);
        setIscurrentState("Contracts");
        updateIconSidebar(e);
      },
      stateVariables: isContracts,
      subItems: [
        {
          id: "listviewContract",
          label: "List View",
          link: "/contracts-list",
          parentId: "contracts",
        },
        {
          id: "overviewContract",
          label: "Overview",
          link: "/apps-contracts-overview",
          parentId: "contracts",
        },
        {
          id: "createContract",
          label: "Create Contracts",
          link: "/apps-contracts-create",
          parentId: "contracts",
        },
      ],
    },
    {
      id: "departments",
      label: "Departments",
      icon: "ph-file-text",
      link: "/#",
      click: function (e: any) {
        e.preventDefault();
        setIsContracts(!isContracts);
        setIscurrentState("Contracts");
        updateIconSidebar(e);
      },
      stateVariables: isContracts,
      subItems: [
        {
          id: "legel",
          label: "Legel",
          link: "/pages-crm",
          parentId: "contracts",
        },
        {
          id: "crm",
          label: "crm",
          link: "/pages-crm",
          parentId: "contracts",
        },
        {
          id: "sales",
          label: "Sales",
          link: "/pages-crm",
          parentId: "contracts",
        },
        {
          id: "customerService",
          label: "Customer Service",
          link: "/pages-crm",
          parentId: "contracts",
        },
        {
          id: "finance",
          label: "Finance",
          link: "/pages-crm",
          parentId: "contracts",
        },
      ],
    },
    {
      id: "support-ticket",
      label: "Support Ticket",
      icon: "ph-ticket",
      link: "/#",
      click: function (e: any) {
        e.preventDefault();
        setIsSupportTicket(!isSupportTicket);
        setIscurrentState("Support Ticket");
        updateIconSidebar(e);
      },
      stateVariables: isSupportTicket,
      subItems: [
        {
          id: "listview-support",
          label: "List View",
          link: "/apps-tickets-list",
          parentId: "support-ticket",
        },
        {
          id: "overview",
          label: "Overview",
          link: "/apps-tickets-overview",
          parentId: "support-ticket",
        },
      ],
    },
    {
      id: "real-estate",
      label: "Real Estate",
      icon: "ph-buildings",
      link: "/#",
      click: function (e: any) {
        e.preventDefault();
        setIsRealEstate(!isRealEstate);
        setIscurrentState("Real Estate");
        updateIconSidebar(e);
      },
      stateVariables: isRealEstate,
      subItems: [
        {
          id: "sign in",
          label: "Properties",
          link: "/apps-real-estate-grid",
          parentId: "real-estate",
        },
        {
          id: "agent",
          label: "Add Property Owner",
          link: "/apps-real-estate-agent-grid-view",
          parentId: "real-estate",
        },
        {
          id: "listingmap",
          label: "Listing Map",
          link: "/apps-real-estate-map",
          parentId: "real-estate",
        },
        {
          id: "property-overview",
          label: "Property Overview",
          link: "/apps-real-estate-property-overview",
          parentId: "real-estate",
        },

        {
          id: "agencies",
          label: "Agencies",
          link: "/#",
          isChildItem: true,
          click: function (e: any) {
            e.preventDefault();
            setIsAgencies(!isagencies);
          },
          stateVariables: isagencies,
          subItems: [
            {
              id: 1,
              label: "List View",
              link: "/apps-real-estate-agencies-list-view",
            },
            {
              id: 3,
              label: "Overview",
              link: "/apps-real-estate-agencies-overview",
            },
          ],
        },
        {
          id: "add-property",
          label: "Add Property",
          link: "/apps-real-estate-add-properties",
          parentId: "real-estate",
        },
        {
          id: "earnings",
          label: "Earnings",
          link: "/apps-real-estate-earning",
          parentId: "real-estate",
        },
      ],
    },
    // {
    //     label: "Contacts",
    //     isHeader: true,
    // },
    {
      id: "contacts",
      label: "Home Owners Contacts",
      link: "/pages-contacts",
      parentId: "pages",
    },

    {
      id: "contractors",
      label: "Contractors Contacts",
      link: "/pages-contractors",
      parentId: "pages",
    },
    {
      id: "credentials",
      label: "Credentials",
      link: "/pages-credentials",
      parentId: "pages",
    },
    {
      id: "marketing",
      label: "Marketing & Website Development",
      icon: "ph-file-text",
      link: "/marketing",
      parentId: "pages",
    },
    {
      id: "pages",
      label: "Settings",
      icon: "ph-address-book",
      link: "/#",
      click: function (e: any) {
        e.preventDefault();
        setIsPages(!isPages);
        setIscurrentState("Pages");
        updateIconSidebar(e);
      },
      stateVariables: isPages,
      subItems: [
        {
          id: "profile",
          label: "Profile",
          link: "/pages-profile",
          parentId: "pages",
        },
        {
          id: "profilesettings",
          label: "Profile Settings",
          link: "/pages-profile-settings",
          parentId: "pages",
        },
      ],
    },
  ];

  // Render only allowed menu items
  const filteredMenuItems = menuItems.filter((item: MenuItem) => {
    if (item.isHeader) {
      return true; // Keep headers
    }

    if (item.subItems) {
      // Filter subItems
      item.subItems = item.subItems.filter((subItem: MenuItem) =>
        isAllowed(subItem.id! || "")
      );
      return item.subItems.length > 0; // Keep parent item if it has allowed subItems
    }

    return isAllowed(item.id! || "");
  });

  return <React.Fragment>{filteredMenuItems}</React.Fragment>;
};

export default Navdata;
